<template>
    <v-container fluid>
        <PlayerControls :metadata="metadata" :is-playing="isPlaying" :loading="streamLoading"
            :artwork-thumbnail="artworkThumbnail" :artwork-small="artworkSmall" :artwork-medium="artworkMedium" @play="play"
            @pause="pause" @backward="backward" @forward="forward" @rewind="rewind" @fastforward="fastForward"
            @seekreq="seek" class="mb-2" />

        <div class="d-flex flex-row justify-space-between">
            <div :class="hideCover ? 'flex-shrink-0 hidden' : 'flex-shrink-0'">
                <audio ref="videoPlayer" class="video-js vjs-controls-disabled" preload="auto" :height="coverSize"
                    :width="coverSize">
                    <p class="vjs-no-js">
                        To view this video please enable JavaScript, and consider
                        upgrading to a web browser that
                        <a href="https://videojs.com/html5-video-support/" target="_blank">
                            supports HTML5 video
                        </a>
                    </p>
                </audio>
            </div>
            <div class="flex-grow-1">
                <Captions :bus="bus" :currentTime="currentTime" :jwtToken="jwtToken" :jwtSource="jwtSource" :entityId="entityId" @seekreq="seek"
                    @updateCaptions="updateCaptions" />
            </div>
        </div>

    </v-container>
</template>

<style scoped>
.hidden {
    display: none;
}
</style>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { isFunction, isString } from "lodash";
import { useTitle } from "@vueuse/core";
import Vue from "vue";
import PlayerControls from "@/components/PlayerControls";
import Captions from "@/components/Captions";
import videojs from "video.js";
import "videojs-contrib-quality-levels";

dayjs.extend(duration);

export default {
    components: { PlayerControls, Captions },

    data: () => ({
        bus: new Vue(),
        entityId: null,
        artist: null,
        title: null,
        album: null,
        artworkThumbnail: null,
        artworkSmall: null,
        artworkMedium: null,
        hideCover: false,
        coverSize: 450,
        isPlaying: false,
        streamLoading: false,
        currentTime: 0,
        jwtToken: null,
        jwtSource: null,
        player: null,
        videoOptions: {
            autoplay: false,
            controls: true,
            sources: [],
        },
    }),

    computed: {
        metadata() {
            return {
                artist: this.artist,
                title: this.title,
                album: this.album,
                currentTime: this.currentTime,
                buffered:
                    this.player && this.player.duration && this.player.buffered
                        ? this.player.buffered().end(0)
                        : 0,
                duration: this.player ? this.player.duration() : 0,
            };
        },
    },

    methods: {
        playerReady() {
            this.streamLoading = false;
        },

        play() {
            this.player.play();
        },

        pause() {
            this.player.pause();
        },

        backward() {
            //constrain to min segments
            if (this.currentSegment > 0) this.currentSegment--;

            const diff = dayjs(
                this.show.episode.segment[this.currentSegment].scheduling.startTime
            ).diff(
                dayjs(this.show.episode.segment[0].scheduling.startTime),
                "second"
            );
            this.player.currentTime(diff ? diff : 0);
            this.player.play();
        },

        forward() {
            //constrain to max segments
            if (this.currentSegment < this.totalSegments - 1) this.currentSegment++;

            const diff = dayjs(
                this.show.episode.segment[this.currentSegment].scheduling.startTime
            ).diff(
                dayjs(this.show.episode.segment[0].scheduling.startTime),
                "second"
            );
            this.player.currentTime(diff ? diff : 0);
            this.player.play();
        },

        rewind() {
            this.player.currentTime(this.player.currentTime() - 10);
        },

        fastForward() {
            this.player.currentTime(this.player.currentTime() + 10);
        },

        seek(value) {
            this.player.currentTime(value || 0);
        },

        async updateCaptions(vttHandle) {
            if (!this.player) return;
            const textTracks = this.player.remoteTextTracks();
            // const foundTrack = find(textTracks.tracks_, { kind: "captions" }); //suddenly this isn't working, so let's manually iterate

            let foundTrack = false;
            for (const textTrack of textTracks.tracks_) {
                if (textTrack.kind === 'captions') foundTrack = textTrack;
                if (foundTrack) {
                    this.player.removeRemoteTextTrack(foundTrack);
                    break;
                }
            }

            this.player.addRemoteTextTrack(
                {
                    src: `${window.__env.baseURL}/captions/vtt/handle/${vttHandle}`,
                    kind: "captions",
                    mode: "showing",
                    srclang: "en",
                    label: "English",
                    default: true,
                },
                false
            );
        },

        updateHasCaptions(hasCaptions) {
            this.hasCaptions = hasCaptions;

            this.captionsLoading = false;
        },
    },

    created() {
        this.coverSize = this.$route.query.coverSize || 450;
        this.hideCover = isString(this.$route.query.hideCover);
    },

    mounted() {
        this.jwtToken = this.$store.state.jwtToken;
        this.jwtSource = this.$store.state.jwtSource;
        this.captionsLoading = true;
        this.entityId = this.$route.params.entityId;
        this.artworkThumbnail = this.$route.query.artwork;
        this.artworkSmall = this.$route.query.artwork;
        this.artworkMedium = this.$route.query.artwork;
        this.artist = this.$route.query.artist;
        this.title = this.$route.query.title;
        this.album = this.$route.query.album;

        useTitle(`Transcription Player`);

        if (!this.enableCaptions) this.updateHasCaptions(false);

        this.videoOptions.sources = [
            {
                src: this.$route.query.audio
            },
        ];

        //only call when we have a source added
        this.player = videojs(this.$refs.videoPlayer, this.videoOptions, () => {
            this.player.poster(this.artworkMedium);
            this.playerReady();
        });

        this.player.on("timeupdate", (e) => {
            if (e.target.player && !isFunction(e.target.player))
                this.currentTime = Math.floor(e.target.player.currentTime() || 0);
        });

        this.player.on("playing", () => {
            this.isPlaying = true;
        });

        this.player.on("pause", () => {
            this.isPlaying = false;
        });

        this.player.on("loadedmetadata", () => {
            this.playerReady();
        });
    },

    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
}
</script>
